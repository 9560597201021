import { APPS } from './analytics';
import { PublicApiTrackingEvents } from './publicApiEvents';
import { RequiredTrackProps } from './types';

type PublicApiTrackerBaseProps = {
  app: APPS;
  baseUrl: string;
  user_id?: string;
  user_type?: string;
};

export const usePublicApiTracker = (props: PublicApiTrackerBaseProps) => {
  const { baseUrl, app, user_id, user_type } = props;
  const logEndpoint = `${baseUrl}/log`;

  return {
    track: async (data: PublicApiTrackingEvents, { onError }: { onError?: (e: Error) => void } = {}) => {
      const extraTrackProps: RequiredTrackProps = {
        user_id: user_id ?? '',
        user_type: user_type ?? '',
        sent_at: new Date().toISOString(),
        app: app,
      };

      fetch(logEndpoint, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        cache: 'no-store',
        method: 'POST',
        body: JSON.stringify({
          data: {
            attributes: {
              ...data,
              ...extraTrackProps,
            } satisfies PublicApiTrackingEvents & RequiredTrackProps,
          },
        }),
      }).catch(onError);
    },
  };
};
