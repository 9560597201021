import { publicApiUrl } from '@sortlist-frontend/data-fetching/public';
import {
  usePublicApiTracker as usePublicApiTrackerBase,
  useTracker as useTackerBase,
} from '@sortlist-frontend/tracking';
import { getStorage, isBrowser } from '@sortlist-frontend/utils';

import { extractDomainRelevantInfo } from '_config/domain.config';

export const useTracker = () => {
  let locale;

  if (isBrowser()) {
    const { origin, pathname } = window.location;
    const { locale: extractedLocale } = extractDomainRelevantInfo(origin, pathname, '');
    locale = extractedLocale;
  }
  return useTackerBase({ app: 'appPublic', locale });
};

export const usePublicApiTracker = () => {
  const anonymousId = getSafeJson(getStorage('ajs_anonymous_id'));
  const loggedUserId = getSafeJson(getStorage('ajs_user_id'));

  return usePublicApiTrackerBase({
    baseUrl: publicApiUrl as string,
    app: 'appPublic',
    user_id: loggedUserId ?? anonymousId ?? '',
    user_type: loggedUserId != null ? 'logged' : anonymousId != null ? 'anonymous' : undefined,
  });
};

const getSafeJson = (json: string | false | null) => {
  if (!json) return json;

  try {
    return JSON.parse(json);
  } catch {
    return json;
  }
};
