import { isNonEmptyString, isUuidV4, UuidV4 } from './typeguards';

export { isValidPhoneNumber } from 'libphonenumber-js';

export const isPhoneNumber = (phone: string) => {
  const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]{7,15}$/;
  return phoneRegex.test(phone);
};

export function assertNonEmptyStringOrNull(
  value: unknown,
  msgOrExceptionFactory?: string | (() => Error) | Error,
  /** auto-trim, default true */
  trim?: boolean,
): asserts value is string {
  const autoTrim = trim ?? true;
  if (value === null) {
    return;
  }
  assertNonEmptyString(value, msgOrExceptionFactory, trim);
}

export function assertNonEmptyString(
  value: unknown,
  msgOrExceptionFactory?: string | (() => Error) | Error,
  /** auto-trim, default true */
  trim?: boolean,
): asserts value is string {
  const autoTrim = trim ?? true;
  if (isNonEmptyString(value, autoTrim)) {
    return;
  }
  if (typeof msgOrExceptionFactory === 'string' || msgOrExceptionFactory === undefined) {
    throw new Error(msgOrExceptionFactory || 'Value must be a non empty string!');
  }
  if (msgOrExceptionFactory instanceof Error) {
    throw msgOrExceptionFactory;
  }
  throw msgOrExceptionFactory();
}

export function assertString(
  value: unknown,
  msgOrExceptionFactory?: string | (() => Error) | Error,
): asserts value is string {
  if (typeof value === 'string') return;
  if (typeof msgOrExceptionFactory === 'string' || msgOrExceptionFactory === undefined) {
    throw new Error(msgOrExceptionFactory || 'Value must be a string!');
  }
  if (msgOrExceptionFactory instanceof Error) {
    throw msgOrExceptionFactory;
  }
  throw msgOrExceptionFactory();
}

export function assertUuidV4(value: unknown, msg?: string): asserts value is UuidV4 {
  if (isUuidV4(value)) return;
  throw new Error(msg || 'Value must be a valid UUID V4!');
}
